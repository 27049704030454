<template>
  <div>
    <div v-for="menuItem in menus" :key="menuItem.code">
      <div
        class="item"
        :class="{ 'link-active': menuItem.isActive }"
        v-if="menuItem.parentId == '0' && !menuItem.children"
        @click="leftBlockClick(menuItem)"
      >
        <div class="menu-btn">
          <div>
            <i :class="`iconfont ${menuItem.icon}`"></i>
            <a target="_blank" @click="$event.preventDefault()">{{ menuItem.menuName }}</a>
          </div>

          <div
            v-if="menuItem.code == 'INDEX'"
            class="isCollapse el-icon-s-fold"
            @click.stop="$emit('update:isCollapse', true)"
          ></div>
        </div>
      </div>

      <div v-else class="kind">
        <div class="title">
          <div>
            <i :class="`iconfont ${menuItem.icon}`"></i>
            <span>{{ menuItem.menuName }}</span>
          </div>
          <i class="iconfont el-icon-arrow-down" style="margin-right: 20px; font-size: 12px"></i>
        </div>
        <div class="content">
          <div
            v-for="item in menuItem.children"
            :key="item.code"
            class="content-item"
            :class="{ 'link-active': item.isActive }"
            @click="onLeftMenuClick(item, menuItem)"
          >
            <span class="menu-btn">
              <a target="_blank" @click="aLeftClick" :href="baseUrl + item.href">
                {{ item.menuName }}
              </a>
              <span v-if="redSpot(item.children)" class="red_spot"></span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'VerMenuEle',
  components: {
    VerMenuEleItem: () => import('./VerMenuEleItem.vue'),
  },
  props: {
    isCollapse: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      menus: state => state.user.menus,
      selectMenu: state => state.user.selectMenu,
    }),
  },
  inject: ['reload'],
  data() {
    return {
      activeName: '',
      baseUrl: window.location.origin + window.location.pathname + '#',
    }
  },
  watch: {
    $route: {
      handler(to, from) {
        this.CLEAR_MENUS_ACTIVE() //清除所有菜单高亮
        if (this.$route.name == 'INDEX' && to.query.code) {
          const codeArr = to.query.code.split('-')
          const father = codeArr[0]
          const son = codeArr[1]
          const findFa = this.menus.find(item => item.code == father)
          if (findFa) {
            const findSon = findFa.children.find(item => item.code == son)
            if (findSon) {
              findSon.isActive = true
              this.SET_SELECT_MENU(findSon)
              this.$forceUpdate()
            }
          }
        } else {
          const findResult = this.menus.find(item => item.code == this.$route.name) //INDEX
          if (findResult) {
            findResult.isActive = true
          } else {
            //说明是多层级的url地址
            if (this.$route.matched.length > 0) {
              const menuOne = this.$route.matched[0]
              const menuTwo = this.$route.matched[1]
              const findOne = this.menus.find(item => item.code == menuOne.name)
              if (findOne) {
                const findTwo = findOne.children.find(item => item.code == menuTwo.name)
                if (findTwo) {
                  findTwo.isActive = true
                  this.SET_SELECT_MENU(findTwo)
                }
              }
            }
          }
          this.$forceUpdate()
        }
      },
      immediate: true,
    },
  },
  created() {},
  methods: {
    aLeftClick(e) {
      e.preventDefault()
    },
    ...mapMutations('user', ['SET_SELECT_MENU', 'CLEAR_MENUS_ACTIVE']),
    leftBlockClick(item) {
      this.SET_SELECT_MENU({}) //清除选中子菜单
      this.CLEAR_MENUS_ACTIVE() //清除所有菜单高亮
      this.jump(item)
      item.isActive = true
      this.$forceUpdate()
    },
    jump(item) {
      let path = item.linkTo ? item.linkTo : item.href
      this.$router.push(path)
    },

    //点击左侧二级菜单
    onLeftMenuClick(item, faItem) {
      //变色高亮
      this.CLEAR_MENUS_ACTIVE() //清除所有菜单高亮
      item.isActive = true
      this.$forceUpdate()
      //跳转
      this.SET_SELECT_MENU({})
      if (item.backlog) {
        this.$api.menu.read(item.code).then(res => {})
      }
      if (item.code == this.$route.name) {
        this.reload()
      } else {
        if (item.children && item.children.length > 0) {
          // 有子菜单需要跳转到首页并给出文字提示
          this.SET_SELECT_MENU(item)
          switch (item.code) {
            case 'PROJECT':
              this.$router.push('/project/ProjectList')
              break
            case 'SWXX':
              const qbsw = item.children.find(swItem => swItem.code == 'QBSW')
              if (qbsw) {
                this.$router.push('/businessManage/allManage/Index')
              } else {
                this.$router.push('/businessManage/Manage')
              }
              break
            case 'AGREEMENT':
              this.$router.push('/agreement/contractList')
              break
            case 'TBGL':
              this.$router.push('/bid/list')
              break
            default:
              this.$router.push('/common?code=' + faItem.code + '-' + item.code)
              break
          }
        } else {
          //没有子菜单直接打开组件页面
          this.jump(item)
        }
      }
      item.backlog = false
    },
    redSpot(row) {
      let show = false
      if (row) {
        row.forEach(v => {
          if (v.backlog) {
            show = true
          }
          if (v.children) {
            v.children.forEach(item => {
              if (item.backlog) {
                show = true
              }
            })
          }
        })
      }
      return show
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/config.scss';
.menu-btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .isCollapse {
    width: 24px;
    font-size: 24px;
    text-align: center;
    margin-right: 20px;
    cursor: pointer;
  }
}
.red_spot {
  width: 8px;
  height: 8px;
  margin-left: 10px;
  border-radius: 50%;
  display: inline-block;
  background: #ff0000a3;
  vertical-align: middle;
  position: absolute;
  top: -5px;
  right: -19px;
}
.iconfont {
  vertical-align: middle;
  margin-right: 8px;
  width: 24px;
  text-align: center;
  font-size: 22px;
  display: inline-block;
  font-weight: lighter;
}

.link-active {
  background: #e6a23c !important;
  color: #fff !important;
}
.item {
  vertical-align: middle;
  padding-left: 20px;
  color: rgb(255, 255, 255);
  background-color: rgba(0, 0, 0, 0);
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  margin-bottom: 1px;
  list-style: none;
  cursor: pointer;
  position: relative;
  transition: border-color 0.3s, background-color 0.3s, color 0.3s;
  box-sizing: border-box;
  white-space: nowrap;
}
.item-cative {
  background: #e6a23c !important;
  color: #fff !important;
}
.kind {
  .title {
    vertical-align: middle;
    padding-left: 20px;
    color: rgb(255, 255, 255);
    background-color: rgba(0, 0, 0, 0);
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    margin-bottom: 1px;
    list-style: none;
    position: relative;
    transition: border-color 0.3s, background-color 0.3s, color 0.3s;
    box-sizing: border-box;
    white-space: nowrap;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    color: rgb(255, 255, 255);
    background: #0a5d79 !important;
    list-style: none;
    position: relative;
    transition: border-color 0.3s, background-color 0.3s, color 0.3s;
    background-color: rgba(0, 0, 0, 0);
    .content-item {
      min-width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 10px 0;
      font-size: 14px;
      cursor: pointer;
      a {
        display: inline-block;
        height: 100%;
        width: 100%;
        text-align: center;
      }
    }
  }
}
</style>
